import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { GET, POST, DELETE } from "../api.js";

export default function useProduct(url, isin) {
  // READ
  const {
    data,
    isLoading,
    error: readError,
    mutate: updateCache,
  } = useSWR(
    isin
      ? {
          url: `${url}?isin=${isin}`,
          args: { isin, isPublic: true },
        }
      : null,
    GET
  );

  // console.log("[INFO]", data);

  // CREATE, UPDATE
  const {
    isMutating,
    trigger,
    error: updateError,
  } = useSWRMutation(url, POST, {
    onSuccess(newItem, key, config) {
      return !data
        ? []
        : updateCache(data.map((e) => (e.id === newItem.id ? newItem : e)));
    },
    revalidate: false,
  });

  // DELETE
  const {
    isMutating: isDeleting,
    trigger: triggerDelete,
    error: deleteError,
  } = useSWRMutation(url, DELETE, {
    onSuccess(newItem, key, config) {
      return !data ? [] : updateCache(data.filter((e) => e.id !== newItem.id));
    },
    revalidate: false,
  });

  return {
    data: !data || data.length === 0 ? [] : data,
    isLoading: isLoading || isDeleting,
    // updateCache: mutate,
    update: trigger,
    isMutating,
    remove: triggerDelete,
    isError: readError || updateError || deleteError,
  };
}
