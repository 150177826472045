import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./components/auth0ProviderWithNavigate.js";
// material
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme.js";
// css
import "./index.css";
// components
import { App } from "./App.js";
// dayjs and plugins
import dayjs from "dayjs";
import calendarPlugin from "dayjs/plugin/calendar";
import isToday from "dayjs/plugin/isToday";
dayjs.extend(calendarPlugin);
dayjs.extend(isToday);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </React.StrictMode>
);
