import React from "react";
// components
import { LandingNav } from "../components/LandingNav.js";
import { LandingFooter } from "../components/LandingFooter.js";
import { LearnHeader } from "../components/LearnHeader.js";
import { LearnContent } from "../components/LearnContent.js";
import Imprint from "../components/Imprint.js";
import TermsOfUse from "../components/TermsOfUse.js";
import GDPRNotice from "../components/GDPRNotice.js";
// material ui
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

const Learn = () => {
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <CssBaseline />
      <LandingNav />
      <LearnHeader />
      <LearnContent />
      <LandingFooter />
      <Imprint />
      <TermsOfUse />
      <GDPRNotice />
    </Box>
  );
};

export default Learn;
