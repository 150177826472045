import { create } from "zustand";
import dayjs from "dayjs";

export const usePageStore = create((set) => ({
  showImprint: false,
  toggleShowImprint: () =>
    set((state) => ({ showImprint: !state.showImprint })),
  showTermsOfUse: false,
  toggleShowTermsOfUse: () =>
    set((state) => ({ showTermsOfUse: !state.showTermsOfUse })),
  showGdprNotice: false,
  toggleShowGdprNotice: () =>
    set((state) => ({ showGdprNotice: !state.showGdprNotice })),
}));

export const useProductStore = create((set) => ({
  product: {},
  setProduct: (payload) => ({ product: payload }),
}));

export const useAuth = create((set) => ({
  accessToken: null,
  userId: null,
  setAccessToken: (payload) => set({ accessToken: payload }),
  setUserId: (payload) => set({ userId: payload }),
}));

export const useUser = create((set) => ({
  user: {
    locale: null,
    firstname: null,
    name: null,
    nickname: null,
    authProvider: null,
    createdAt: null,
    id: null,
    emailVerified: null,
    email: null,
    surname: null,
  },
  setUser: (payload) => set({ user: payload }),
}));

export const useNav = create((set) => ({
  tabId: 0,
  showBottomNav: false,
  setTabId: (payload) => set({ tabId: payload }),
  toggleBottomNav: () =>
    set((state) => ({ showBottomNav: !state.showBottomNav })),
  closeBottomNav: () => set({ showBottomNav: false }),
}));

export const useGoalStore = create((set) => ({
  title: "",
  category: "",
  frequency: "today",
  dueTime: dayjs().endOf("day"),
  setTitle: (payload) => set({ title: payload }),
  setCategory: (payload) => set({ category: payload }),
  setFrequency: (payload) => set({ frequency: payload }),
  setDueTime: (payload) => set({ dueTime: payload }),
  resetGoalStore: () =>
    set({ title: "", category: "", frequency: "today", dueTime: dayjs() }),
}));

export const useTimelineStore = create((set) => ({
  items: [],
  setItems: (payload) => set({ items: payload }),
}));
