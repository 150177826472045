import * as React from "react";
import Dialog from "./Dialog.js";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { usePageStore } from "../hooks/useStore.js";

export default function GDPRNotice() {
  const { showGdprNotice, toggleShowGdprNotice } = usePageStore();
  return (
    <Dialog
      title="GDPR Notice"
      width={"sm"}
      open={showGdprNotice}
      toggleOpen={toggleShowGdprNotice}
      content={
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            1. Data Collection
          </Typography>
          <Typography variant="body1" paragraph>
            We collect personal data such as names, email addresses, and any
            other information you provide directly to us through forms or
            communication.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            2. Purpose of Data Collection
          </Typography>
          <Typography variant="body1" paragraph>
            Your data is collected for providing services, improving the user
            experience, and communicating important updates. We will not use
            your data for purposes beyond those stated without your consent.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            3. Data Sharing
          </Typography>
          <Typography variant="body1" paragraph>
            We do not share your personal data with third parties except when
            required by law or to provide essential services such as hosting and
            payment processing.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            4. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            Personal data will be retained only for as long as necessary to
            fulfill the purposes outlined above or as required by law.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            5. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to access, correct, or delete your personal data
            at any time. To exercise these rights, please contact us at
            info[at]nennwert.de.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            6. Data Security
          </Typography>
          <Typography variant="body1" paragraph>
            We take appropriate technical and organizational measures to ensure
            the security of your personal data against unauthorized access or
            disclosure.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            7. Changes to this Notice
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to update this GDPR notice as needed. Any
            changes will be posted on this page.
          </Typography>
        </Box>
      }
    />
  );
}
