import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function Imprint({
  title,
  content,
  buttons,
  width,
  open,
  toggleOpen,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={toggleOpen}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={width}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            borderRadius: "1em",
          },
          elevation: 2,
        }}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: "blur(8px)", // blur effect for the backdrop
              backgroundColor: "rgba(255, 255, 255, 0.5)", // semi-transparent white for frosted look
            },
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={toggleOpen}
          sx={(theme) => ({
            position: "absolute",
            right: 16,
            top: 13,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={true}>{content}</DialogContent>
        {buttons && <DialogActions>{buttons.map((e) => e)}</DialogActions>}
      </Dialog>
    </React.Fragment>
  );
}
