import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// material ui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import useDebounce from "../hooks/useDebounce";
import useProductSearch from "../hooks/useProductSearch";

const ProductSearch = () => {
  const [open, setOpen] = useState(false);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(productSearchTerm, 500);
  const { data: options, isLoading } = useProductSearch(
    "/products/search",
    debouncedSearchTerm
  );
  const navigate = useNavigate();

  const handleInputChange = (_, value) => {
    setProductSearchTerm(value);
  };

  const handleOptionClick = (option) => {
    navigate(`/products/${option.isin}`);
  };

  return (
    <Autocomplete
      open={open && options.length > 0}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={isLoading}
      filterOptions={(x) => x}
      renderOption={(props, option) => {
        const {
          isin,
          title,
          issuer: { legal_name },
        } = option;
        return (
          <Box
            key={`product-search-${isin}`}
            sx={{
              pt: "1em",
              px: "2em",
              mb: "1em",
              "&:hover": { cursor: "pointer" },
            }}
            onClick={() => handleOptionClick(option)}
          >
            <Typography variant="body2" sx={{ fontSize: "110%" }}>
              {isin} | {legal_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", fontSize: "120%" }}
            >
              {title}
            </Typography>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Find product by name or ISIN"
          type="search"
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{ width: "100%" }}
        />
      )}
    />
  );
};

export default ProductSearch;
