import * as React from "react";
import Dialog from "./Dialog.js";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { usePageStore } from "../hooks/useStore.js";

export default function Imprint() {
  const { showImprint, toggleShowImprint } = usePageStore();

  return (
    <Dialog
      title="Imprint"
      width={"xs"}
      open={showImprint}
      toggleOpen={toggleShowImprint}
      content={
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Information according to section 5 TMG (German Telemedia Act)
          </Typography>
          <Typography variant="body1">
            Company: nennwert UG (haftungsbeschränkt) i.G.
          </Typography>
          {/* <Typography variant="body1">Kapellenstr. 15</Typography>
          <Typography variant="body1">Seligenstadt</Typography> */}
          <Typography variant="body1">Country: Germany</Typography>
          <Typography variant="body1">Email: info[at]nennwert.de</Typography>
          {/* <br />
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Verantwortlich für den Inhalt gemäß §55 Absatz 2 RStV.
            (Rundfunkstaatsvertrag)
          </Typography>
          <Typography variant="body1">Paul Springstein</Typography> */}
        </Box>
      }
    />
  );
}
