import React from "react";
// material ui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import BookmarkIcon from "@mui/icons-material/BookmarkBorderOutlined";
import {
  SECURITIES_DATA_MAPPING,
  REGISTRAR_DATA_MAPPING,
  ISSUER_DATA_MAPPING,
} from "../constants.js";
import ProductSearch from "./ProductSearch.js";

const SectionTitle = ({ text }) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h4" sx={{ mb: ".1em" }}>
        {text}
      </Typography>
      <Box
        sx={{
          width: "33%",
          borderBottom: `.3em solid ${theme.palette.primary.main}`,
          mb: "2em",
        }}
      />
    </>
  );
};

const Field = ({ label, text }) => {
  return (
    <Box sx={{ mb: "1em" }}>
      <Typography variant="body2">{label}</Typography>
      <Box sx={{ pr: "1em", width: "100%", borderTop: "1px solid #aaa" }} />
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", overflowWrap: "break-word" }}
      >
        {text}
      </Typography>
    </Box>
  );
};

const LinkField = ({ label, text }) => {
  return (
    <Box sx={{ mb: "1em" }}>
      <Typography variant="body2">{label}</Typography>
      <Box sx={{ pr: "1em", width: "100%", borderTop: "1px solid #aaa" }} />
      <a
        href={`/files/${text}.pdf`}
        style={{ fontWeight: "bold", overflowWrap: "break-word" }}
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </a>
    </Box>
  );
};

export const ProductContent = ({ data, isLoading, isError }) => {
  const theme = useTheme();

  if (data.length === 0) {
    return null;
  }

  const { security, issuer, registrar } = data;
  const secArray = Object.keys(security);
  const issArr = issuer ? Object.keys(issuer) : [];
  const regArr = registrar ? Object.keys(registrar) : [];

  return (
    <>
      <Box sx={{ background: theme.palette.primary.main, p: "1em" }}>
        <Box sx={{ backgroundColor: "#FFF" }}>
          <ProductSearch />
        </Box>
      </Box>
      <Box sx={{ p: { xs: "1em", sm: "2em" } }}>
        <Box
          sx={{
            border: "1px solid darkgrey",
            p: { xs: "1em", sm: "2em" },
            mb: "1em",
          }}
        >
          {Object.keys(security).length === 0 ? (
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Product not found
            </Typography>
          ) : (
            <Grid container>
              <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: "4em" } }}>
                <Grid container>
                  <Grid item xs={12}>
                    <SectionTitle text="Securities" />
                    {secArray.map((key, i) => {
                      if (
                        key === "id" ||
                        key === "created_at" ||
                        key === "issuer_lei" ||
                        key === "registrar_lei" ||
                        key === "prospectus_file_name" ||
                        key === "final_terms_file_name" ||
                        key === "risk_factors_summary"
                      ) {
                        return null;
                      }
                      return (
                        <Field
                          label={SECURITIES_DATA_MAPPING[0][key]}
                          text={security[key]}
                          key={`security-field-${security.isin}-${i}`}
                        />
                      );
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    <SectionTitle text="Files" />
                    {secArray.map((key, i) => {
                      if (
                        key === "prospectus_file_name" ||
                        key === "final_terms_file_name"
                      ) {
                        return (
                          <LinkField
                            label={SECURITIES_DATA_MAPPING[0][key]}
                            text={security[key]}
                            key={`security-field-security-files-${security.isin}-${i}`}
                          />
                        );
                      }
                      return null;
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container sx={{ pr: "2em" }}>
                  <Grid item xs={12}>
                    <SectionTitle text="Issuer" />
                    {issArr.map((key, i) => {
                      if (
                        key === "id" ||
                        key === "created_at" ||
                        key === "role_id"
                      ) {
                        return null;
                      }
                      return (
                        <Field
                          label={ISSUER_DATA_MAPPING[0][key]}
                          text={issuer[key]}
                          key={`security-field-issuer-${security.isin}-${issuer.lei}-${i}`}
                        />
                      );
                    })}
                  </Grid>
                  {regArr.length !== 0 && (
                    <Grid item xs={12}>
                      <SectionTitle text="Registrar" />
                      {regArr.map((key, i) => {
                        if (
                          key === "id" ||
                          key === "created_at" ||
                          key === "role_id"
                        ) {
                          return null;
                        }
                        return (
                          <Field
                            label={REGISTRAR_DATA_MAPPING[0][key]}
                            text={registrar[key]}
                            key={`security-field-registrar-${security.isin}-${registrar.lei}-${i}`}
                          />
                        );
                      })}
                    </Grid>
                  )}
                  {security &&
                    security.risk_factors_summary &&
                    security.risk_factors_summary.length !== 0 && (
                      <Grid item xs={12}>
                        <SectionTitle text="Risk Factors" />
                        <Box>
                          {security.risk_factors_summary.map((e, i) => {
                            return (
                              <Box
                                key={`product-risk-${i}`}
                                sx={{ display: "flex", alignItems: "top" }}
                              >
                                <BookmarkIcon sx={{ marginRight: "0.5em" }} />
                                <Typography
                                  variant="body1"
                                  sx={{ mb: ".5em", fontSize: "90%" }}
                                >
                                  {e.summary}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};
