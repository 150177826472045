import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// import { AuthenticationGuard as ProtectedRoute } from "./components/authenticationGuard.js";
// import Home from "./views/home.js";
import Splash from "./views/splash.js";
import Landing from "./views/landing.js";
import Learn from "./views/learn.js";
import Product from "./views/product.js";
// import Callback from "./views/callback.js";

export const App = () => {
  const { isLoading } = useAuth0();
  const [showSplashscreen, setShowSplashscreen] = useState(
    () => !JSON.parse(sessionStorage.getItem("nennwert-showedSplashscreen"))
  );

  useEffect(() => {
    setTimeout(() => {
      setShowSplashscreen(false);
      sessionStorage.setItem(
        "nennwert-showedSplashscreen",
        JSON.stringify(true)
      );
    }, 2000);
  }, []);

  return showSplashscreen || isLoading ? (
    <Splash />
  ) : (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/products/:isin" element={<Product />} />
      <Route path="/learn" element={<Learn />} />
      {/* <Route path="/app" element={<ProtectedRoute component={Home} />} />
      <Route path="/callback" element={<Callback />} /> */}
    </Routes>
  );
};
