import useSWR from "swr";
import { GET } from "../api.js";

export default function useProductSearch(url, searchTerm) {
  const { data, isLoading, error } = useSWR(
    searchTerm
      ? { url: `${url}?s=${searchTerm}`, args: { isPublic: true } }
      : null,
    GET
  );

  return {
    data: !data || data.length === 0 ? [] : data,
    isLoading,
    isError: error,
  };
}
