import React from "react";
// material ui
// import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
// components
import { YouTubeEmbed } from "./YouTubeEmbed.js";
import { Typography } from "@mui/material";
import { LEARN_UNITS } from "../constants.js";

export const LearnContent = () => {
  // const theme = useTheme();
  const data = LEARN_UNITS.sort((a, b) => b.id - a.id);

  return (
    <Box>
      {data.map((e) => {
        return (
          <Box
            sx={{
              // background: theme.palette.primary.main,
              // p: "1em",
              mb: "1em",
              border: "1px solid darkgrey",
              m: "2em",
            }}
            key={`unit-${e.id}`}
          >
            <Grid
              container
              sx={{
                p: "1em",
              }}
            >
              <Grid item xs={12} sm={8} sx={{ pr: { xs: 0, sm: "1em" } }}>
                <Box sx={{ height: "420px" }}>
                  <YouTubeEmbed id={e.youTubeId} />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ p: "1em", backgroundColor: "#454545" }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    pb: ".5em",
                    color: "#FFF",
                    fontWeight: 900,
                    fontFamily: "Arial Rounded MT Bold",
                  }}
                >
                  {e.title}
                </Typography>
                <Typography variant="body1" sx={{ pb: "1em", color: "#FFF" }}>
                  {e.description}
                </Typography>
                {e.keywords.map((e, i) => {
                  return (
                    <Chip
                      key={`hashtag-${i}`}
                      color="secondary"
                      label={e}
                      sx={{ mr: ".5em", mb: ".5em" }}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};
