import React from "react";
// material ui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const ProductHeader = ({ data }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        overflow: "hidden",
        p: "3vw",
        textAlign: "center",
        backgroundColor: "#F2F2F2",
      }}
    >
      <Typography
        variant="h4"
        sx={{ fontFamily: "Calibri", color: theme.palette.primary.main }}
      >
        {data?.security
          ? data.security.title
          : "Unlock the power of tokenised debt security investments"}
      </Typography>
    </Box>
  );
};
