import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: "#0000FF",
      },
      name: "primary",
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: "#000",
      },
      name: "secondary",
    }),
    neutral: theme.palette.augmentColor({
      color: {
        main: "#A4A8A9",
      },
      name: "neutral",
    }),
    white: theme.palette.augmentColor({
      color: {
        main: "#FFF",
      },
      name: "white",
    }),
    success: theme.palette.augmentColor({
      color: {
        main: "#2effc1",
      },
      name: "success",
    }),
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            fontWeight: 600,
          },
        },
      },
    },
  },
});

export default theme;
