export const YouTubeEmbed = ({ id }) => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
        border: 0,
      }}
      title={`https://www.youtube.com/embed/${id}`}
      src={`https://www.youtube.com/embed/${id}`}
      // allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share;"
      allowFullScreen
    ></iframe>
  );
};
