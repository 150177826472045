import React from "react";
// components
import { LandingNav } from "../components/LandingNav.js";
import { LandingHeader } from "../components/LandingHeader.js";
import { LandingFooter } from "../components/LandingFooter.js";
import { LandingContent } from "../components/LandingContent.js";
import Imprint from "../components/Imprint.js";
import TermsOfUse from "../components/TermsOfUse.js";
import GDPRNotice from "../components/GDPRNotice.js";
// material ui
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

const Landing = () => {
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <CssBaseline />
      <LandingNav />
      <LandingHeader />
      <LandingContent />
      <LandingFooter />
      <Imprint />
      <TermsOfUse />
      <GDPRNotice />
    </Box>
  );
};

export default Landing;
