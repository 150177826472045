import React from "react";
// material ui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ProductSearch from "./ProductSearch.js";

export const LandingContent = () => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{ background: theme.palette.primary.main, p: "1em" }}>
        <Box sx={{ backgroundColor: "#FFF" }}>
          <ProductSearch />
        </Box>
      </Box>
    </>
  );
};
