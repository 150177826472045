import Box from "@mui/material/Box";
import logoUrlSrc from "../images/logo_full.png";

const Splash = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "98vh",
        background: "white",
        p: "2vh",
      }}
    >
      <img
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        src={logoUrlSrc}
        alt="nennwert"
      />
    </Box>
  );
};

export default Splash;
