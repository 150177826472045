// handlers
import { isDev } from "./util.js";

function handleMissingAccessToken(url) {
  const error = `Skipped fetching ${url}. No access token present.`;
  isDev && console.error(error);
  return {
    status: "error",
    data: null,
    error,
  };
}

function getUrl(url) {
  return `${
    process.env.NODE_ENV === "development" ? "" : process.env.REACT_APP_API_URL
  }${url}`;
}

// api exports
export const GET = async ({ url, args }) => {
  const { accessToken, isPublic } = args;

  if (isPublic) {
    return fetch(getUrl(url), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        isDev && console.log("fetch get response", data);
        return data;
      })
      .catch((error) => console.error(error));
  }

  if (!accessToken) {
    return handleMissingAccessToken(url);
  }

  return fetch(getUrl(url), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      isDev && console.log("fetch get response", data);
      return data;
    })
    .catch((error) => console.error(error));
};

export const POST = async (url, args) => {
  const {
    arg: { accessToken, data },
  } = args;

  if (!accessToken) {
    return handleMissingAccessToken(url);
  }

  return fetch(getUrl(url), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ data }),
  })
    .then((response) => response.json())
    .then((data) => {
      isDev && console.log("fetch post response", data);
      return data;
    })
    .catch((error) => console.error(error));
};

export const DELETE = async (url, args) => {
  const {
    arg: { accessToken, data },
  } = args;

  if (!accessToken) {
    return handleMissingAccessToken(url);
  }

  return fetch(getUrl(url), {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ data }),
  })
    .then((response) => response.json())
    .then((data) => {
      isDev && console.log("fetch delete response", data);
      return data;
    })
    .catch((error) => console.error(error));
};
