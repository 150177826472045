export const VIEWS = [
  {
    id: 0,
    key: "hub",
    title: "Hub",
  },
  {
    id: 1,
    key: "securities",
    title: "Securities",
  },
];

export const SECURITIES_DATA_MAPPING = [
  {
    isin: "ISIN",
    issuer_lei: "Issuer LEI",
    registrar_lei: "Registrar LEI",
    title: "Title",
    dlt_name: "DLT Network",
    dlt_adress: "Smart Contract Address",
    dlt_blockexplorer_url: "Block Explorer URL",
    issue_date: "Issue Date",
    maturity_date: "Maturity Date",
    currency: "Currency",
    denomination: "Denomination",
    issue_price: "Issue Price",
    aggregate_principal_amount: "Aggregate Principal Amount",
    form: "Form",
    interest: "Interest",
    redemption: "Redemption",
    early_redemption: "Early Redemption",
    governing_law: "Governing Law",
    prospectus: "Prospectus",
    prospectus_date: "Prospectus Date",
    prospectus_file_name: "Prospectus Download",
    final_terms_file_name: "Final Terms Download",
    listing: "Listing",
    listing_segment: "Listing Segment",
  },
];

export const REGISTRAR_DATA_MAPPING = [
  {
    lei: "Legal Entity Identifier (LEI)",
    legal_name: "Name",
    address: "Address",
    jurisdiction: "Jurisdiction",
  },
];

export const ISSUER_DATA_MAPPING = [
  {
    lei: "Legal Entity Identifier (LEI)",
    legal_name: "Name",
    address: "Address",
    jurisdiction: "Jurisdiction",
  },
];

export const LEARN_UNITS = [
  {
    id: 1,
    youTubeId: "TYFuiotMEEU",
    category: "tokenization",
    title: "How Tokenizing Real-World Assets is Changing Finance",
    description:
      "Discover how tokenization is impacting the investment landscape by converting physical assets like real estate, art, and commodities into digital tokens on the blockchain. This groundbreaking approach brings liquidity, transparency, and accessibility to traditionally illiquid markets, opening up new investment opportunities for everyone.",
    keywords: ["#tokenization", "#blockchain", "#realworldassets"],
  },
  {
    id: 2,
    youTubeId: "2bt_BN6iZUI",
    category: "tokenization",
    title: "How Digital Tokens Transform Vinyl Record Collecting",
    description:
      "Explore the groundbreaking concept of vinyl tokenization, where the nostalgia of vinyl records meets the cutting-edge technology of blockchain. In this video, we dive into how vinyl tokenization allows collectors and music enthusiasts to own, trade, and benefit from vinyl records in the digital world. By transforming physical vinyl into digital tokens, the process ensures authenticity, provenance, and value are securely stored on the blockchain.",
    keywords: ["#tokenizedassets", "#blockchain", "#musicindustry"],
  },
  {
    id: 3,
    youTubeId: "O7T-hJKgLuY",
    category: "tokenization",
    title: "How Will Robots Make You Rich with Tokenization?",
    description:
      "Imagine a home where your personal robot isn't just smart but fully autonomous, managing your assets and optimizing your wealth with cutting-edge AI and blockchain technology. This is the future of digital asset management—where humanoid robots and blockchain tokenization work together to revolutionize your financial life.",
    keywords: ["#ai", "#tokenization", "#humanoidrobots"],
  },
  {
    id: 4,
    youTubeId: "rUH0O51CKnA",
    category: "tokenization",
    title: "How Do ERC-20 Tokens Work in Ethereum Smart Contracts?",
    description:
      "In this video, we take an in-depth look at Ethereum tokens, with a focus on the ERC-20 token standard, which forms the foundation of decentralized finance (DeFi) on the Ethereum blockchain. We explore what tokens are, how they function, and how smart contracts on the Ethereum Virtual Machine (EVM) manage and transfer these digital assets.",
    keywords: ["#erc20", "#ethereum", "#smartcontracts"],
  },
  {
    id: 5,
    youTubeId: "tJimd-aOil0",
    category: "tokenization",
    title: "Why Indexes Are the Key to Understanding Your Investment Portfolio",
    description:
      "In this video, we explore the fascinating world of financial indices and their critical role in helping investors make informed decisions. Imagine opening your financial dashboard and seeing numbers flashing, markets moving, and investments fluctuating. In the midst of all this volatility, an index stands as a beacon, offering insight into how your investments perform relative to the broader market.",
    keywords: ["#indices", "#indexinvesting", "#S&P500"],
  },
];
