import React from "react";
// components
import { LandingNav } from "../components/LandingNav.js";
import { ProductHeader } from "../components/ProductHeader.js";
import { LandingFooter } from "../components/LandingFooter.js";
import { ProductContent } from "../components/ProductContent.js";
// material ui
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
// hooks
import { useParams } from "react-router-dom";
import useProduct from "../hooks/useProduct.js";

const Product = () => {
  const { isin } = useParams();
  const { data, isLoading, isError } = useProduct("/product/get", isin);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <CssBaseline />
      <LandingNav />
      <ProductHeader data={data} />
      <ProductContent data={data} isLoading={isLoading} isError={isError} />
      <LandingFooter />
    </Box>
  );
};

export default Product;
