import React from "react";
// material ui
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const LandingHeader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: "hidden",
        p: "4vw",
        textAlign: "center",
        backgroundColor: "#F2F2F2",
      }}
    >
      <Typography
        variant="h3"
        sx={{ fontFamily: "Calibri", color: theme.palette.primary.main }}
      >
        Unlock the power of tokenised debt security investments
      </Typography>
    </Box>
  );
};
