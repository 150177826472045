import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export const ExploreButton = () => {
  const navigate = useNavigate();
  const handleRedirect = () => navigate("/");

  return (
    <Button
      variant="text"
      color="secondary"
      size="large"
      sx={{
        color: "#000",
        "&:hover": {
          fontWeight: "bold",
        },
      }}
      onClick={handleRedirect}
    >
      Explore
    </Button>
  );
};
