import React from "react";
// components
import { ExploreButton } from "../components/buttons/explore-button";
import { LearnButton } from "../components/buttons/learn-button";
// import { LoginButton } from "../components/buttons/login-button";
// import { SignupButton } from "./buttons/signup-button";
// import { AccessButton } from "../components/buttons/access-button";
// material ui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// images
import logoUrlSrc from "../images/logo.png";

export const LandingNav = () => {
  return (
    <AppBar position="static" color="white">
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
            zIndex: 9999,
          }}
          onClick={() => {
            window.location.pathname = "/";
          }}
        >
          <img
            style={{
              width: "38px",
              height: "38px",
              paddingTop: "2px",
              marginRight: "1em",
              justifyContent: "center",
            }}
            src={logoUrlSrc}
            alt="nennwert Logo"
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              fontSize: "150%",
              fontFamily: "Arial Rounded MT Bold",
            }}
          >
            <span style={{ fontWeight: 900 }}>nennwert</span>
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 4,
            textAlign: { xs: "right", sm: "right", md: "center", lg: "right" },
            ml: { xs: 0, md: "-165px" },
          }}
        >
          <ExploreButton />
          <LearnButton />
          {/* <LoginButton />
          <SignupButton /> */}
          {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <AccessButton />
          </Box> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
