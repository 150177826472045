import React from "react";
// material ui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { usePageStore } from "../hooks/useStore.js";

export const LandingFooter = () => {
  const { toggleShowImprint, toggleShowTermsOfUse, toggleShowGdprNotice } =
    usePageStore();

  return (
    <Box
      sx={{
        mt: "1em",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          width: { xs: "40%", sm: "90%", md: "75%", lg: "50%" },
          textAlign: "center",
          borderTop: "1px solid #000",
          display: { xs: "block", sm: "flex" },
          p: "1em",
          justifyContent: "center",
        }}
      >
        <Box sx={{ mr: { xs: 0, sm: "1em" } }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "110%",
              fontFamily: "Arial Rounded MT Bold",
              mt: "-4px",
            }}
          >
            <span style={{ fontWeight: 900 }}>nennwert</span>
          </Typography>
        </Box>
        <Typography variant="body1">© 2024</Typography>
        <Box sx={{ display: { xs: "none", sm: "block" }, mx: "1em" }}>/</Box>
        <Typography
          variant="body1"
          sx={{
            "&:hover": {
              fontWeight: "bold",
              cursor: "pointer",
            },
          }}
          onClick={toggleShowImprint}
        >
          Imprint
        </Typography>
        <Box sx={{ display: { xs: "none", sm: "block" }, mx: "1em" }}>/</Box>
        <Typography
          variant="body1"
          sx={{
            "&:hover": {
              fontWeight: "bold",
              cursor: "pointer",
            },
          }}
        >
          Connect
        </Typography>
        <Box sx={{ display: { xs: "none", sm: "block" }, mx: "1em" }}>/</Box>
        <Typography
          variant="body1"
          sx={{
            "&:hover": {
              fontWeight: "bold",
              cursor: "pointer",
            },
          }}
          onClick={toggleShowTermsOfUse}
        >
          Terms of Use
        </Typography>
        <Box sx={{ display: { xs: "none", sm: "block" }, mx: "1em" }}>/</Box>
        <Typography
          variant="body1"
          sx={{
            "&:hover": {
              fontWeight: "bold",
              cursor: "pointer",
            },
          }}
          onClick={toggleShowGdprNotice}
        >
          GDPR
        </Typography>
      </Box>
    </Box>
  );
};
