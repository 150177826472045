import * as React from "react";
import Dialog from "./Dialog.js";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { usePageStore } from "../hooks/useStore.js";

export default function TermsOfUse() {
  const { showTermsOfUse, toggleShowTermsOfUse } = usePageStore();

  return (
    <Dialog
      title="Terms of Use"
      width={"sm"}
      open={showTermsOfUse}
      toggleOpen={toggleShowTermsOfUse}
      content={
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing this website, you agree to the following terms. If you
            do not accept these terms, please refrain from using the site.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            2. Informational Purpose Only
          </Typography>
          <Typography variant="body1" paragraph>
            The content, including articles and YouTube videos, is for
            educational and informational purposes only. It does not constitute
            financial or legal advice.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            3. No Investment Advice
          </Typography>
          <Typography variant="body1" paragraph>
            This website does not offer investment recommendations or financial
            advice. Users should consult professionals before making financial
            decisions.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            4. No Warranties
          </Typography>
          <Typography variant="body1" paragraph>
            We strive for accuracy, but we do not guarantee the completeness or
            timeliness of information. Use the information at your own risk.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            5. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            We are not liable for any losses or damages arising from the use of
            this website.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            6. External Links
          </Typography>
          <Typography variant="body1" paragraph>
            Our site may link to third-party websites. We are not responsible
            for the content or policies of these external sites.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            7. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content is protected by copyright laws. You may not reproduce,
            distribute, or use the content without permission.
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            8. Changes to Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify these terms at any time. Continued
            use of the site implies acceptance of the updated terms.
          </Typography>
        </Box>
      }
    />
  );
}
